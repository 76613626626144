.btn-primary {
  background-color: black;
  border-color: black;
}

.button-add-box,
.button-delete-box {
  width: 100%;
  background-color: $bg-white;
  color: $color-silver;
  font-size: 14px;
  line-height: 22px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 11px;
  font-weight: 400;

  img {
    width: 20px;
    height: 20px;
  }
}

.button-add-box {
  border: 1px dashed $color-wheat;

  &:active,
  &:focus,
  &:hover {
    background-color: $bg-white !important;
    color: $color-silver !important;
    border: 1px solid $color-wheat;
    transform: translateY(-1px);
  }
}

.button-delete-box {
  border: 1px dashed $color-red;

  &:active,
  &:focus,
  &:hover {
    background-color: $bg-white !important;
    color: $color-silver !important;
    border: 1px solid $color-red;
    transform: translateY(-1px);
  }
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:active {
  box-shadow: none;
}

.btn-reset {
  width: 100%;
  height: 36px;
  background-color: $color-titleBody !important;
  color: $color-white !important;
  border-radius: 5px !important;
}

.reset-btn-search {
  .input-group-text {
    background-color: $bg-white;
    border-right: none;
  }
  input {
    border-left: none;
  }
  .form-control {
    padding: 0.375rem 0.75rem 0.375rem 0;
  }
}
