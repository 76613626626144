.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.avatar,
.avatar img {
  height: 40px;
  width: 40px;
}
