@import './../../styles/variable.scss';

.buttonHome {
  min-width: 50px !important;
}

.tooltip {
  display: flex;
  padding: 30px 0 20px 0;
  border-bottom: 1px solid $color-borderTable;
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  p {
    margin: 0;
    margin-left: 10px;
  }
  p.name {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: $color-navy;
  }
  p.inforOther {
    font-size: 12px;
    line-height: 20px;
    color: $color-silver;
  }
}
.box {
  cursor: pointer;
  margin: 10px 15px 10px 10px;
  width: 120px;
  height: 120px;
  backdrop-filter: blur(10px);
  border-radius: 20px;
  display: inline-block;
  transition: all ease-out 0.2s;
  &:hover {
    background: $bg-box-home;
  }
  .logoAcms {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    text-align: center;
    margin: 10px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-navActive;
  }
  .description {
    font-size: 12px;
    line-height: 20px;
    color: $color-nav;
    margin: 4px 0 0 0;
  }
  .redirect {
    text-decoration: none;
  }
}
