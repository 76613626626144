html,
body {
  height: 100%;
}

body {
  overflow: unset !important;
  background-color: $bg-body;
  background-size: cover;
  background-repeat: no-repeat;
  color: $color-white;
  padding: 0px !important;

}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.bg-menu {
  background-color: $bg-menu;
}

.pd-0 {
  padding: 0px !important;
}

.strike {
  height: 1px;
  width: 100%;
  background-color: $color-grey;
  margin: 15px 0;
}

.circle-orange {
  color: $color-yellow !important;
  background: $color-yellow1 !important;
  border-color: $color-yellow !important;
}

.circle-green {
  color: $color-green !important;
  background: $color-green1 !important;
  border-color: $color-green !important;
}

.circle-pink {
  color: $color-pink !important;
  background: $color-pink1 !important;
  border-color: $color-pink !important;
}

.circle-blue {
  color: $color-blue2 !important;
  background: $color-blue3 !important;
  border-color: $color-blue2 !important;
}

.circle-grey {
  color: $color-grey !important;
  background: $color-tableStripped !important;
  border-color: $color-grey !important;
}

.border-orange {
  border-color: $color-yellow !important;
}

.border-green {
  border-color: $color-green !important;
}

.border-pink {
  border-color: $color-pink !important;
}

.border-blue {
  border-color: $color-blue2 !important;
}

.border-grey {
  border-color: $color-grey !important;
}

.bg-code-green {
  background: $color-green !important;
}

.bg-code-pink {
  background: $color-pink !important;
}

.bg-code-blue {
  border-color: $color-blue2 !important;
}

.bg-code-grey {
  background: $color-grey !important;
}

.bg-white {
  background-color: $bg-white !important;
}

.border-circle {
  border-radius: 999px !important;
}

.border-smooth-square {
  border-radius: 5px !important;
}

@media (min-width: 768px) {
  .main-content {
    .container-fluid {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }
}

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: inherit;
  border: none;
}

.rdtCounter .rdtCount {
  padding: 0;
}

body {
  background-color: $bg-white;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.ml-13 {
  margin-left: 13px;
}

.ml-40 {
  margin-left: 40px;
}

.mr--15 {
  margin-right: -15px !important;
}

.clear-both {
  clear: both;
}

.table-responsive {
  @media (min-width: 1024px) {
    display: inline-table;
  }
}

.col-delete-edit {
  width: 50px;
}

.hidden {
  display: none !important;
}

p {
  font-weight: normal;
  font-size: 14px;
}

.fs-14 {
  font-size: 14px !important;
  line-height: 22px;
}

.fs-16 {
  font-size: 16px !important;
  line-height: 22px;
}

.color-white {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-white !important;
}

.color-charcoal {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-charcoal;
}

.color-yellow {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-yellow;
}

.color-blue-gray {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-blueGray;
}

.color-blue-gray {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-blueGray;
}

.color-blue1 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-blue1 !important;
}

.color-green {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-green !important;
}

.color-green2 {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-green2 !important;
}

.color-red {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-red !important;
}

.color-navy {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: $color-navy !important;
}

.btn-bg-yellow2 {
  background-color: $bg-yellow2;
  color: $color-yellow;
  border: 1px solid $bg-yellow2;

  &:active {
    background-color: $bg-yellow2 !important;
    color: $color-yellow !important;
    border: 1px solid $bg-yellow2 !important;
  }

  &:hover {
    background-color: $color-yellow;
    color: $color-white;
    border: 1px solid $color-yellow;
  }
}

.btn-dropdown {
  margin-right: 15px;

  button {
    box-shadow: 0 1px 3px #32325d26, 0 1px 0 #00000005;
    background-color: $bg-white !important;
    transition: box-shadow 0.15s ease;

    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 22px;
    color: $color-silver;
    font-weight: normal;
  }
}

.avatar {
  margin-right: 5px;
  overflow: hidden;
  color: #fff;
  background-color: #adb5bd;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  img {
    object-fit: cover;
  }
}

.table {
  color: $color-charcoal;
  margin-bottom: 0;

  td,
  th {
    font-size: 14px;
  }
}

.table-list {
  color: black;
  background: #ffffff;
  border: 1px solid $color-borderTable;
  box-sizing: border-box;
  border-radius: 5px;
  box-sizing: border-box;
  border-radius: 5px;

  thead {
    tr {
      background-color: $bg-salmon;
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $color-navy;
        text-transform: inherit;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: $bg-rowTable;
      }
    }
  }
}

.table-border-show {
  box-sizing: border-box;
  box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
  border-radius: 5px;

  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiTableContainer-root {
    border-radius: 5px;
  }
}

.table-border-bottom-none {
  .MuiTableCell-root {
    border-bottom: 0px;
  }
}

.table-pagination {
  p {
    margin-bottom: 5px;
  }
  .MuiButtonBase-root {
    .MuiSvgIcon-root {
      color: $color-yellow;
    }
    &.Mui-disabled {
      .MuiSvgIcon-root {
        color: $bg-yellow1h;
      }
    }
  }
  .MuiTablePagination-displayedRows {
    position: absolute;
    left: 17px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    flex-shrink: 0;
  }
}

.border-show {
  box-sizing: border-box;
  box-shadow: 0px 5px 25px 4px rgba(34, 51, 84, 0.15);
  border-radius: 5px;
}

.border-table {
  border: 1px solid $color-wheat;
  box-sizing: border-box;
  border-radius: 5px;
}

.col-action {
  color: $color-silver;
  svg {
    font-size: 25px;
  }
  .remove,
  .edit {
    &:hover {
      cursor: pointer;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
  }
}

.text-bold {
  font-weight: 500;
}

.fw-bold {
  font-weight: bold !important;
}

.title-body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $color-titleBody;
  margin-bottom: 20px;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.text-truncate {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #8d8d8d;
}

.box-session {
  border: 1px solid $color-grey;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 5px;
  border: 1px solid $color-grey;

  .card-body-top {
    padding: 15px 20px 0px 20px;
  }
}

.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.pd-0 {
  padding: 0px !important;
}

.pd-15 {
  padding: 15px;
}

.form-control,
.form-control:focus {
  color: $color-charcoal;
}

.input-border {
  border: 1px solid $color-inputColor;

  &:active,
  &:visited,
  &:focus {
    border-color: $color-green;
  }
}

.input-border-radius {
  border-radius: 5px;
}

.box-show-none {
  .input-group-alternative,
  .form-control-alternative {
    box-shadow: none;
  }
  .input-group-prepend {
    margin-right: 0px;
  }

  .input-group {
    border: 1px solid $color-wheat;

    .input-group-prepend + .rdt .form-control {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  .input-group-text {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
}

.rdtOpen input {
  border-top: 1px solid $color-green !important;
  border-bottom: 1px solid $color-green !important;
  border-left: 1px solid $color-green !important;
}
.rdtOpen ~ .input-group-prepend .input-group-text {
  border-right: 1px solid $color-green !important;
  border-top: 1px solid $color-green !important;
  border-bottom: 1px solid $color-green !important;
}

.fieldset-group:focus-within label {
  color: $color-green;
}

.calendar-input {
  width: 20px;
}

.fieldset-group {
  .form {
    position: relative;
    top: 0px;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      border: 1px solid $color-inputColor;
      box-shadow: none;

      &:focus {
        border-color: $color-green;
      }
    }

    &__label {
      position: absolute;
      left: 0.8rem;
      top: 0.8rem;
      padding: 0 0.5rem;
      color: $color-truncate;
      cursor: text;
      transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
      background-color: $bg-white;
    }

    ::placeholder {
      color: $color-white;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $color-white;
    }

    ::-ms-input-placeholder {
      color: $color-white;
    }
  }

  .form__input:focus ~ .form__label,
  .form__input:not(:placeholder-shown).form__input:not(:focus) ~ .form__label {
    top: -0.5rem;
    font-size: 0.8rem;
    left: 0.8rem;
  }
}

.error {
  color: $color-red;
  margin-bottom: 0;
  font-size: 14px;
}

.error-input {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.error-no-data {
  color: $color-red;
  padding: 30px !important;
  text-align: center;
  font-size: 1rem !important;
}

.w-170 {
  width: 170px;
}

.ct-form-controller-filter {
  .form-control {
    height: auto;
    padding: 0.425rem 0.75rem;
  }
  .input-group-text {
    padding: 0.225rem 0.75rem;
  }
}

.auto-scroll-hidden {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.auto-scroll-hidden::-webkit-scrollbar {
  display: none;
}

.auto-scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.detail-breadcrumb {
  margin-bottom: 20px !important;

  .breadcrumb {
    padding-left: 0;
    background-color: transparent;
  }

  .previous-name {
    padding-left: 0;
    padding-right: 0;
    color: $color-truncate;
    font-weight: 400;
    margin-top: 7px;
    font-size: 14px;
  }

  .active-name {
    font-size: 14px;
    color: $color-navy;
    font-weight: 500;
  }
}

@media (max-width: 1060px) {
  .title-body-height {
    height: 48px;
  }
}

.nav-fixed {
  position: sticky;
  background: $bg-body;
  top: 55px;
  z-index: 999;
  padding-top: 10px;
  padding-bottom: 5px;
}

.pl-15 {
  padding-left: 15px;
}

.onsignal {
  display: flex;
  .avatar-noti-onsignal {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 50%;
  }
}
