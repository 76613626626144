.pageLogin {
  .loading {
    position: absolute;
    z-index: 1000;
    margin-left: 50%;
    margin-top: 25%;
  }
  .blur {
    opacity: 0.5;
  }
  .boxVideo {
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    video {
      object-fit: cover;
      height: 100vh;
      position: absolute;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 25%;
      top: 50%;
      right: 0;
    }
  }

  .boxLogin {
    padding: 0px 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;

    @media (max-width: 1024px) {
      padding: 0px 15px;
    }
    @media (max-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .with100 {
      width: 100%;
      @media (max-width: 768px) {
        background-color: #0000006e;
        width: 100%;
        padding: 20px;
        border: none;
      }
    }

    .title {
      font-weight: 500;
      font-size: 34px;
      color: #223354;
      display: flex;

      span {
        width: 5px;
        margin-right: 10px;
        background-color: #fea628;
        display: inline-block;
        box-shadow: 0px 5px 25px rgba(34, 51, 84, 0.15);
        border-radius: 4px;
      }

      @media (max-width: 768px) {
        color: white;
      }
    }

    .slogan {
      margin-top: 25px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;

      p {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 0px;
        color: #8d8d8d;

        @media (max-width: 768px) {
          color: white;
        }
      }

      img {
        margin: 0px 20px;
        @media (max-width: 1024px) {
          margin: 0px 10px;
        }
      }
    }

    .buttonLogin {
      :global {
        .MuiButton-root {
          width: 100%;
          background: #fea628;
          border-radius: 5px;
          padding: 15px 16px;
          text-align: center;
          text-transform: inherit;

          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
  }
}
