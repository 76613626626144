@import './../../../styles/variable.scss';

ul {
  list-style: none;
  padding-left: 0;
}

.export {
  margin-left: 10px;
  width: 170px;
}

.paddingLeft {
  padding-left: 10px;
}
.loading {
  margin-bottom: 10%;
}
.errorNoData {
  text-align: center;
  color: $color-red;
  padding: 20px 0;
}

.flex {
  display: flex;
}

.center {
  text-align: center;
}

.button {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  margin-top: -5px;
}

.content {
  background-color: $bg-tooltip;
  .title {
    display: flex;
    line-height: 31px;
    .labelTitle {
      width: 3px;
      background-color: $color-buttonSubmit;
      margin-right: 5px;
      height: 31px;
    }
    .textTitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      line-height: 31px;
      color: $color-navActive;
    }
  }

  .input-search-box {
    float: right;
  }

  .profileBox {
    margin-top: 20px;
    cursor: pointer;
    .assetHeader {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }

    .assetHeaderRow {
      background-color: $color-headTable !important;
    }

    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }

    .assetContent {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      td {
        cursor: pointer;
      }
    }

    .assetHeader tr {
      background-color: $color-headTable !important;
    }

    tr:nth-child(odd) {
      background-color: $bg-white;
    }

    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }

  .profileBox {
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }

    :global {
      .css-o9ey65 {
        position: relative;
        .MuiTablePagination-displayedRows.css-1chpzqh {
          position: absolute;
          left: 17px;
        }

        .MuiTablePagination-actions {
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
            color: $color-menuActive;
            font-size: 30px;
          }
        }
      }
    }

    .tablePagination {
      p {
        margin-bottom: 5px;
      }
      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          color: $color-yellow;
        }
        &.Mui-disabled {
          .MuiSvgIcon-root {
            color: $bg-yellow1h;
          }
        }
      }
      .MuiTablePagination-displayedRows {
        position: absolute;
        left: 17px;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        flex-shrink: 0;
      }
    }

    table {
      width: 100%;
      margin: 0;
      table-layout: fixed;

      .profileContentInfo {
        word-wrap: break-word;
        font-weight: normal;
      }

      .thead {
        background-color: $color-headTable;

        .profileContentInfo {
          color: $color-titleBody;
        }
      }

      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }
    .createButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 17px 0;
      height: 30px;
      background: $color-navActive;
      border-radius: 5px;
      color: $color-white;
      width: 95px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;

      .textButton {
        width: 70px !important;
      }

      .imgReset {
        margin: '0 10px 0 0';
      }

      &:hover {
        background: $color-navActive;
        color: $color-white;
      }

      &:active {
        background: $color-blue !important;
        color: $color-white !important;
      }
    }
  }
}

.linkRequest {
  color: $color-titleExport;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}
.email {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 0 0;
  color: $color-email;
}
.colorWaiting {
  color: $color-blue1;
}
.colorDeny {
  color: $bg-menuNumberInfo;
}
.colorAccept {
  color: $color-active;
}

.infoDetail {
  display: flex;
  padding: 23px 20px 20px 20px;
  background-color: $color-headTable;
  .statusInfo {
    line-height: 20px;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .info {
    width: 100%;
  }
  .linkRequest {
    line-height: 18px !important;
  }
  .email {
    line-height: 18px !important;
  }
  .buttonAcceptHr {
    margin-top: 5px;
    .hrAccept {
      width: 170px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
.infoProfile {
  padding: 15px 25px;
  .infoProfileElement {
    padding: 20px 0;
    margin-right: 20px;
    border-bottom: 1px solid $color-borderTable;
    .infoProfileLabel {
      color: $color-titleBody;
      font-weight: 500;
    }
    .infoProfileContent {
      color: $color-charcoal;
      font-weight: normal;
    }
  }
}

.modal {
  :global {
    .modal-body {
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    :global {
      .MuiPaper-root {
        min-width: 673px;
        margin: 1.75rem auto;
      }
    }
  }
}

.modal {
  :global {
    .modal-body {
      padding: 0;
    }
  }
  @media (min-width: 1024px) {
    :global {
      .MuiPaper-root {
        min-width: 673px;
        margin: 1.75rem auto;
      }
    }
  }
}

.modalCreateContract {
  :global {
    .MuiGrid-item {
      margin-top: 4px;
    }
  }

  .exampleSelectMulti {
    appearance: none;
    width: 100%;
    cursor: pointer;
    border: 1px solid $color-inputColor;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: $color-white;
    justify-content: center;
    align-items: flex-start;
    padding: 5px 0;
    padding-left: 13px;
    height: auto;
    color: $color-truncate;
  }
  .exampleSelectMulti:hover {
    cursor: pointer;
  }

  .footerModal {
    width: 100%;
    padding-left: 15px;
    margin-bottom: -15px;
    :global {
      .MuiGrid-item {
        margin-top: 0px;
        padding-top: 15px;
      }
    }
  }
  .colorCheckBox {
    :global {
      .MuiTypography-root {
        font-size: 14px;
        color: $color-charcoal;
      }
    }
  }
}
.filter {
  display: flex;
  justify-content: space-between;
  .search {
    width: 56%;
  }
  .fieldSelect {
    width: 40%;
    :global {
      .MuiFormControl-root {
        height: 35px;
        label {
          top: -7px;
          &:focus {
            top: 2px;
          }
        }
        .MuiOutlinedInput-root {
          height: 38px;
        }
        .MuiSelect-select {
          text-align: left;
          padding-right: 0px;
        }
      }
    }
  }
}
