.MuiFormControl-root {
  width: 100% !important;
}

.MuiList-root {
  .MuiMenuItem-root {
    font-size: 14px;
  }
}
.css-dhopo2 {
  min-height: 250px !important;
}
