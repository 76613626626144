@import "./../../../styles/variable.scss";

.center {
  text-align: center;
}

.content {
  background-color: $bg-tooltip;
  .title {
    display: flex;
    line-height: 31px;
    .labelTitle {
      width: 3px;
      background-color: $color-buttonSubmit;
      margin-right: 5px;
      height: 31px;
    }
    .textTitle {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      line-height: 31px;
      color: $color-navActive;
      .manageMember {
        font-weight: 100;
        font-size: 14px;
        line-height: 22px;
        color: #919191;
        text-decoration: none;
        &:hover {
          color: #223354;
          font-weight: 500;
        }
      }
      .detail {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
      .space {
        color: #919191;
        font-size: 14px;
        margin-left: 10px;
      }
    }
    .textTitleAuthorization {
      margin-top: 40px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #223354;
    }
  }
  .profileBox {
    margin-top: 20px;
    cursor: pointer;
    :global {
      .MuiPaper-root {
        box-shadow: none;
      }
    }
    .assetHeader {
      color: $color-black;
      line-height: 2rem;
      font-size: 0.9rem;
      font-weight: bold;
      cursor: auto;
    }
    .assetHeaderRow {
      background-color: $color-headTable !important;
    }
    :global {
      .MuiTableCell-root {
        text-align: left;
      }
    }
    .assetHeader tr {
      background-color: $color-headTable !important;
    }
    tr:nth-child(odd) {
      background-color: $bg-white;
    }
    tr:nth-child(even) {
      background-color: $bg-tableStripped;
    }
  }
  .profileBox {
    padding: 0;
    box-shadow: 0px 5px 25px $color-black2;
    border-radius: 5px;

    :global {
      .MuiTableCell-root {
        white-space: normal !important;
        word-wrap: break-word !important;
      }
      .css-15wwp11-MuiTableHead-root {
        .MuiTableCell-root {
          color: $color-titleBody;
          font-weight: 500;
        }
      }
    }

    :global {
      .css-o9ey65 {
        position: relative;
        .MuiTablePagination-displayedRows.css-1chpzqh {
          position: absolute;
          left: 17px;
        }

        .MuiTablePagination-actions {
          .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
            color: $color-menuActive;
            font-size: 30px;
          }
        }
      }
    }
    table {
      width: 100%;
      margin: 0;
      table-layout: fixed;

      .profileContentInfo {
        word-wrap: break-word;
        font-weight: normal;
      }

      .thead {
        background-color: $color-headTable;

        .profileContentInfo {
          color: $color-titleBody;
        }
      }
      .short {
        width: 20%;
      }
      tr {
        font-weight: 500;
        color: $color-charcoal;
      }
    }
  }
}
.linkRequest {
  color: $color-titleExport;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-decoration: none;
}
.boxInfor {
  color: black;
  background: #f9fbfc;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0 0 0;
  .centerLoading {
    padding: 50px 0 50px 48%;
  }
  p {
    margin: 0 0 0 0;
    font-weight: 500;
    padding: 20px 0 0 16px;
    color: #223354;
  }
  .inforBasic {
    font-size: 16px;
    line-height: 19px;
  }
  .name {
    font-size: 18px;
    line-height: 18px;
  }
  .inforValue {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #333333;
    padding: 0 0 0 16px;
  }
  .inforTitle {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  .label {
    padding: 20px 0 0 16px;
  }
}
.backGroundCustom {
  top: 55px;
  z-index: 999;
  padding-top: 10px;
  padding-bottom: 5px;
}
