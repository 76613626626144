@import "styles/variable.scss";
.home {
  .weather {
    padding: 100px 0 0 12%;
    display: flex;
    .imgCloud {
      margin-left: 100px;
    }
    p {
      margin: 12px 0 0 20px;
      font-weight: 300;
      font-size: 24px;
      line-height: 28px;
      color: $bg-tooltip;
      span {
        font-size: 16px;
        line-height: 24px;
        margin-left: 10px;
      }
    }
    p.normal {
      font-family: system-ui;
    }
  }
  .borderBottom {
    height: 1px;
    width: 80%;
    background: $color-border-bottom;
    margin: 30px 0 0 14%;
  }
  .system {
    .title {
      padding: 40px 0 0 18%;
      display: flex;
      .branch {
        width: 3px;
        height: 31px;
        background: $color-yellow;
        box-shadow: 0px 5px 25px $color-box-shadow;
        border-radius: 4px;
      }
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: $color-childTable;
        margin: 4px 0 0 10px;
      }
    }
  }
  .totalSystem {
    padding: 40px 0 0 18%;
    .box {
      cursor: pointer;
      width: 182px;
      height: 149px;
      border-radius: 20px;
      margin-right: 20px;
      margin-bottom: 20px;
      transition: all ease-out 0.2s;
      &:hover {
        background: $bg-box-system;
      }
      .logoAcms {
        width: 70px;
        height: 70px;
        margin-top: 10px;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      p {
        text-align: center;
        margin: 10px 0 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $color-table;
      }
      .description {
        font-size: 12px;
        line-height: 20px;
        color: $color-headTable;
        margin: 6px 0 0 0;
      }
      .redirect {
        text-decoration: none;
      }
    }
  }
}
